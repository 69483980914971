<template>
  <b-card
    title="จัดเรียงข้อมูล"
    class="form-system"
  >
    <draggable
      v-model="dataTable"
      tag="ul"
      class="list-group list-group-flush cursor-move"
    >
      <b-list-group-item
        v-for="(item, index) in dataTable"
        :key="index"
        tag="li"
      >
        <strong> {{ Number(index) + 1 }}. {{ item.system_name }}</strong>
      </b-list-group-item>
    </draggable>
    <!-- Form Actions -->
    <div class="d-flex mt-2">
      <b-overlay
        :show="overlaySubmit"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <b-button
          variant="primary"
          class="mr-2"
          @click="fncSubmit()"
        >
          <feather-icon icon="SaveIcon" />
          บันทึกข้อมูล
        </b-button>
      </b-overlay>
      <b-button
        type="button"
        variant="outline-secondary"
        :to="{ path: '/system' }"
      >
        ยกเลิก
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BListGroupItem, BOverlay, BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import useSystemSort from './useSystemSort'

export default {
  components: {
    BCard,
    BListGroupItem,
    BOverlay,
    BButton,
    draggable,
  },

  setup() {
    const {
      // variable data
      dataTable,
      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
    } = useSystemSort()

    return {
      // variable data
      dataTable,
      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
    }
  },
}
</script>

<style>

</style>
