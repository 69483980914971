import { ref, onMounted } from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSystemSort() {
  // Notification
  const toast = useToast()

  // variable data
  const dataTable = ref([])

  // loading animation
  const overlaySubmit = ref(false)

  const fncSubmit = () => {
    overlaySubmit.value = true

    const payload = {
      data_system: dataTable.value,
    }

    axios
      .put('/System/Sort', { requestData: payload })
      .then(() => {
        router.push('/system')

        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'จัดเรียงข้อมูลระบบสำเร็จ',
          },
        })

        overlaySubmit.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาดในการแก้ไขข้อมูล [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlaySubmit.value = false
      })
  }

  const fncFetchDataEdit = () => {
    axios
      .get('/System')
      .then(response => {
        dataTable.value = response.data.value.data
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  onMounted(() => {
    fncFetchDataEdit()
  })

  return {
    // variable data
    dataTable,
    // loading animation
    overlaySubmit,

    // function
    fncSubmit,
  }
}
